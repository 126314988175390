<template>
    <div>
        <router-view v-show="$route.matched.length==3"></router-view>
        <PageHeaderLayout v-show="$route.matched.length==2">
            <div class="main-page-content">
                <el-row class="table-header" type="flex" justify="space-between">
                    <el-col :span="18">
                        <el-button type="primary" size="medium"
                                   v-if="userPermissions.indexOf('daily_practice_create') != -1 "
                                   @click="addButton()">添加
                        </el-button>
                    </el-col>
<!--                    <el-col :span="5" :offset="1">-->
<!--                        <change-question-bank @confirm="onChangeQuestionBankClick"></change-question-bank>-->
<!--                    </el-col>-->
                </el-row>


              <el-row class="table-search">
                <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                  <el-form-item label="名称">
                    <el-input placeholder="请输入名称" clearable v-model="searchForm.name"/>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="onSearchClick" style="margin-left: 12px">搜索</el-button>
                  </el-form-item>
                  <el-form-item>
                    <el-button @click="onResetSearch">重置</el-button>
                  </el-form-item>
                </el-form>
              </el-row>

                <ApeTable ref="apeTable" :data="questionBankList" :columns="columns" :loading="loadingStatus"
                          :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row>
                    <el-table-column
                            slot="first-column"
                            width="80"
                            align="center"
                            label="Drag">
                        <template slot-scope="scope">
                            <el-tooltip effect="dark" content="拖动排序" placement="top-start">
                                <span class="drag-handle" :data-id="scope.row.id"><i class="el-icon-rank"></i></span>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column
                            v-if="buttonType=='icon'"
                            label="操作"
                            width="200"
                            fixed="right">
                        <template slot-scope="scope">
              <span>
                     <el-tooltip effect="dark" content="选题" placement="top-start"
                                 v-if="userPermissions.indexOf('daily_practice_add') != -1">
                  <el-button size="mini" type="primary" icon="el-icon-plus"
                             @click="addQuestionButton(scope.row.id)"></el-button>
                </el-tooltip>
                     <!--<el-tooltip effect="dark" content="选文章" placement="top-start"-->
                                 <!--v-if="userPermissions.indexOf('daily_practice_article') != -1">-->
                  <!--<el-button size="mini" type="info" icon="el-icon-document-add"-->
                             <!--@click="addArticleButton(scope.row.id)"></el-button>-->
                <!--</el-tooltip>-->

                     <!--<el-tooltip effect="dark" content="克隆到今日" placement="top-start"-->
                                 <!--v-if="userPermissions.indexOf('daily_practice_copy') != -1">-->
                  <!--<el-button size="mini" type="primary" icon="el-icon-document-copy"-->
                             <!--@click="addCloneButton(scope.row.id)"></el-button>-->
                <!--</el-tooltip>-->

                <el-tooltip effect="dark" content="编辑" placement="top-start"
                            v-if="userPermissions.indexOf('daily_practice_edit') != -1">
                  <el-button size="mini" icon="el-icon-edit" @click="editButton(scope.row.id)"></el-button>
                </el-tooltip>

                  <el-tooltip effect="dark" content="删除" placement="top-start"
                              v-if="userPermissions.indexOf('daily_practice_delete') != -1">
                  <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteButton(scope.row.id)"></el-button>
                </el-tooltip>

              </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            v-if="buttonType=='text'"
                            label="操作"
                            width="200"
                            fixed="right">
                        <template slot-scope="scope">
                            <div>
                                <el-button size="mini" type="primary"
                                           v-if="userPermissions.indexOf('daily_practice_add') != -1"
                                           @click="addQuestionButton(scope.row.id)">选题
                                </el-button>

                                <!--<el-button size="mini" type="primary"-->
                                           <!--v-if="userPermissions.indexOf('daily_practice_article') != -1"-->
                                           <!--@click="addArticleButton(scope.row.id)">文章-->
                                <!--</el-button>-->

                                <!--<el-button size="mini" type="primary" icon="el-icon-document-copy"-->
                                           <!--v-if="userPermissions.indexOf('daily_practice_copy') != -1"-->
                                           <!--@click="addCloneButton(scope.row.id)">克隆到今日-->
                                <!--</el-button>-->

                                <el-button size="mini" v-if="userPermissions.indexOf('daily_practice_edit') != -1"
                                           @click="editButton(scope.row.id)">编辑
                                </el-button>

                                <el-button type="danger"
                                               size="mini"
                                               @click="deleteButton(scope.row.id)"
                                               v-if="userPermissions.indexOf('daily_practice_delete') != -1">删除
                                </el-button>

                            </div>
                        </template>
                    </el-table-column>
                </ApeTable>
            </div>
        </PageHeaderLayout>
        <ApeDrawer :drawerData="drawerData" @drawerClose="drawerClose" @drawerConfirm="drawerConfirm">
            <template slot="ape-drawer">
                <el-col :span="18" class="content-left">
                    <el-form ref="TestPaperForm" :model="formData" :rules="rules" label-width="80px">
                        <el-form-item label="当前题库">
                            <el-input v-model="curChooseBankName" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="名称" prop="name">
                            <el-input v-model="formData.name" placeholder="请输入名称"></el-input>
                        </el-form-item>
                        <el-form-item label="日期" prop="exercise_date">
                            <div class="block">
                                <el-date-picker
                                        v-model="formData.exercise_date"
                                        format="yyyy 年 MM 月 dd 日"
                                        value-format="yyyy-MM-dd"
                                        type="date"
                                        placeholder="选择日期">
                                </el-date-picker>
                            </div>
                        </el-form-item>
                        <el-form-item label="难度" prop="difficulty">
                            <el-select
                                    v-model="formData.difficulty"
                                    filterable
                                    clearable
                                    placeholder="请选择难度"
                            >
                                <el-option
                                        v-for="item in difficultList"
                                        :key="item.id"
                                        :label="item.title"
                                        :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="备注" >
                            <el-input v-model="formData.description" type="textarea" ></el-input>
                        </el-form-item>
                        <el-form-item label="状态" prop="status">
                            <el-radio-group v-model="formData.status">
                                <el-radio label="0" border>启用</el-radio>
                                <el-radio label="-1" border>禁用</el-radio>
                            </el-radio-group>
                        </el-form-item>

                    </el-form>
                </el-col>
            </template>
        </ApeDrawer>
    </div>
</template>

<script>

    const DEL = "del";
    const NO_DEL = "no_del";
    const WINDOW_LOCAL_ORIGIN=process.env.VUE_APP_POROXY_TARGET;
    import ChangeQuestionBank from "../../components/ChangeQuestionBank";
    import PageHeaderLayout from '@/layouts/PageHeaderLayout';
    import ApeTable from '@/components/ApeTable';
    import ApeDrawer from '@/components/ApeDrawer';
    import GetQuestionBankMixins from '../mixins/GetQuestionBankMixins'
    import {mapGetters} from 'vuex';
    import Sortable from 'sortablejs'

    export default {
        components: {
            ChangeQuestionBank,
            PageHeaderLayout,
            ApeTable,
            ApeDrawer,
        },
        mixins: [GetQuestionBankMixins],

        data() {
            return {
                loadingStatus: false,
                columns: [
                    {
                        title: '名称',
                        value: 'name',
                    },
                    {
                        title: '难度',
                        value: "difficulty_alias",
                    },
                    {
                        title: '总分',
                        value: "score",
                    },
                    {
                        title: '题目数量',
                        value: "question_count",
                    },
                    {
                        title: '练习时间',
                        value: 'exercise_date',
                    },
                ],
                // 表格列表数据
                questionBankList: [],
                // 分页信息
                pagingData: {
                    is_show: true,
                    layout: 'total, sizes, prev, pager, next, jumper',
                    total: 0,
                    offset: 0, // 分页的offset,序号列使用
                },
                // 抽屉数据
                drawerData: {
                    visible: false,
                    loading: true,
                    loading_text: '玩命加载中……',
                    title: '',
                    width_height: '560px',
                },
                //抽屉表单
                formData: {
                    name: "",
                    exercise_date:"",
                    difficulty:1,
                    status: '0'
                },
                // 表单验证
                rules: {
                    name: [
                        {required: true, message: '请输入标题', trigger: 'blur'},
                    ],
                    exercise_date:[
                        {required: true, message: '请选择时间', trigger: 'blur'},
                    ],
                    status: [
                        {required: true, message: '请选择题型状态', trigger: 'blur'}
                    ],
                    difficulty:[
                        {required: true, message: '请选择难度', trigger: 'blur'}
                    ],
                },

                //难度
                difficultList: [
                    {
                        title: "容易",
                        value: 1
                    },
                    {
                        title: "困难",
                        value: 3
                    }
                ],

                searchForm: {},

                searchParams:{}

            }
        },
        computed: {
            ...mapGetters(['userPermissions', 'buttonType', 'questionBankInfo','curChooseBankName','curChooseBankId'])
        },
        methods: {
            // 切换页码操作
            async switchPaging() {
                this.initDailyPracticeList(NO_DEL)
            },

            // 响应添加按钮
            async addButton() {
                this.drawerData.loading_text = '玩命加载中……';
                this.drawerData.visible = true;
                this.drawerData.title = '添加每日练习';
                this.$nextTick(() => {
                    this.drawerData.loading = false
                });

                this.formData.qb_id = this.curChooseBankId;
            },

            // 响应编辑按钮
            async editButton(id) {
                this.drawerData.loading_text = '玩命加载中……';
                this.drawerData.visible = true;
                this.drawerData.title = '修改每日练习';
                let {info} = await this.$api.getDailyInfo({id});
                this.$nextTick(() => {
                    this.drawerData.loading = false;
                    this.formData = info;
                    this.formData.difficulty = Math.ceil(info.difficulty);
                });
            },

            //响应选文章按钮
            addArticleButton(id) {
                let qbId = this.curChooseBankId;
                this.$router.push(this.$route.path + '/' + id + '/' + qbId + '/article/select');
            },

            //响应题型添加
            addQuestionButton(id) {
                let qbId = this.curChooseBankId;
                this.$router.push(this.$route.path + '/' + id + '/' + qbId + '/create');
            },

            //响应克隆按钮添加
            async addCloneButton(id) {
                //todo 传Id
                this.$confirm('是否克隆该记录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let info =  this.$api.cloneDailyResource({id});
                    this.initDailyPracticeList(DEL);
                }).catch(() => {

                });

                // 刷新列表
                this.initDailyPracticeList(DEL);
            },

            // 处理抽屉关闭
            drawerClose() {
                this.resetFormData();
                this.drawerData.visible = false;
                this.drawerData.loading = true
            },

            // 处理抽屉确认
            async drawerConfirm() {
                // 调用组件的数据验证方法
                this.$refs['TestPaperForm'].validate((valid) => {
                    if (valid) {
                        this.formSubmit()
                    } else {
                        this.$message.error('数据验证失败，请检查必填项数据！')
                    }
                })
            },

            // form数据提交，请求接口
            async formSubmit() {
                this.drawerData.loading_text = '玩命提交中……';
                this.drawerData.loading = true;

                // 调用接口
                let id = await this.$api.storeDaily(this.formData).catch((err) => {
                    this.drawerData.loading = false;
                });
                this.drawerData.loading = false;

                this.$nextTick(() => {
                    if (id) {
                        this.drawerData.visible = false;
                        this.initDailyPracticeList(NO_DEL);
                        this.$message.success('保存成功!');
                        this.resetFormData()
                    }
                });

            },

            // 相应删除按钮
             deleteButton(id) {
                this.$confirm('是否删除该记录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async() => {
                    let info = await this.$api.deleteDailyInfo({id});
                    if (info == 'ok') {
                        this.$nextTick(() => {
                            this.$message.success("删除成功");
                            this.initDailyPracticeList(DEL);
                        })
                    } else {
                        this.$message.error(info)
                    }
                })

            },

            // 初始化数据
            resetFormData() {
                // 初始化form表单
                this.formData = {
                    difficulty:1,
                    name: "",
                    exercise_date:"",
                    status: '0',
                };
                this.$nextTick(() => {
                    this.$refs['TestPaperForm'].resetFields();
                })
            },

            async initDailyPracticeList(type, params = {}) {
                this.loadingStatus = true;
                let inputData = this.$refs['apeTable'].getPagingInfo(type);

                for (let param in params) {
                    inputData[param] = params[param];
                }

                let qbId = this.curChooseBankId;

                // if (!qbId) {
                //     let userInfo = JSON.parse(localStorage.getItem('user_info'));
                //     let name = WINDOW_LOCAL_ORIGIN + '_' + userInfo.id +'_'+'qb_info';
                //     let qbInfo = JSON.parse(localStorage.getItem(name));
                //     qbId = qbInfo.id;
                // }

                inputData.qb_id = qbId;//题库ID


                let {list} = await this.$api.getDailyList(inputData);

                this.$nextTick(() => {
                    this.questionBankList = list.data;
                });

                this.pagingData.total = list.pages.total;
                this.pagingData.offset = list.pages.offset;
                this.loadingStatus = false
            },

            // 拖拽排序数据提交，请求接口
            async dragSortSubmit(orderIds) {
                let data = {
                    ids: orderIds
                };
                let info = await this.$api.orderDailyList(data);
                if (info == 'ok') {
                    this.$nextTick(() => {
                        this.initTestPaperList(NO_DEL)
                    })
                }
                this.$message.success('保存成功!')
            },

            // 表格拖拽排序，同层级移动有效果
            dragSort() {
                const el = document.querySelectorAll('.el-table__body-wrapper > table > tbody')[0];
                this.sortable = Sortable.create(el, {
                    handle: ".drag-handle",
                    setData: function (dataTransfer) {
                        dataTransfer.setData('Text', '')
                    },
                    onEnd: () => {
                        let Ids = [];
                        let tmp = el.querySelectorAll('.drag-handle');
                        for (let i = 0, len = tmp.length; i < len; i++) {
                            Ids.push(tmp[i].dataset.id)
                        }
                        this.dragSortSubmit(Ids)
                    },
                })
            },

            //搜索
            onSearchClick() {
                this.$refs['apeTable'].resetCurPageSize();
                this.initDailyPracticeList(DEL, this.searchForm);
            },

            //重置搜索
            onResetSearch() {
                this.searchForm = {};
                this.initDailyPracticeList();
            },

            onChangeQuestionBankClick(){
                this.initDailyPracticeList(DEL);
            }

        },

        mounted() {
            this.initDailyPracticeList(DEL);
            this.$nextTick(() => {
                this.dragSort()
            })
        },

        watch: {
            "$route.matched": function (n, o) {
                if (n.length === 2) {
                    this.initDailyPracticeList(DEL);
                }
            },
          'curChooseBankId'(newVal) {
            if (newVal) {
              this.initDailyPracticeList(DEL);
            }
          }
        },
    }
</script>

<style lang="stylus" scoped>
    .table-header
        margin-bottom 12px

    .drag-handle
        font-size 24px
        cursor pointer

    .el-input-group__prepend, .el-input-group__append
        background #ffffff
        padding 0 12px

    .el-input-group__append
        color #ffffff
        background #1890ff

    .el-popover .el-checkbox-group .el-checkbox
        margin-left 0px
        margin-right 12px

    .el-radio.is-bordered
        width 100px

    .el-color-picker
        position absolute
</style>